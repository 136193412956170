import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import SlideVerify from 'vue-monoplasty-slide-verify'



Vue.use(Vant);
Vue.use(SlideVerify)//图形验证插件
Vue.config.productionTip = false


import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
// router.beforeEach(function(to, from, next) {
//   if (to.meta.needLogin) {
//     //页面是否登录
//     if (localStorage.getItem("token")) {
//       //本地存储中是否有token(uid)数据
//       next({
//         name: "dataList"
//       });
//        //表示已经登录
//     } else {
//       //next可以传递一个路由对象作为参数 表示需要跳转到的页面
//       next();
//     }
//   } else {
//     //表示不需要登录
//     next(); //继续往后走
//   }
// });


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
