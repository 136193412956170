import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    // meta:{
    //   needLogin: true
    // },
  },
  {
    path: '/dataList',
    name: 'dataList',
    meta:{
      keepAlive: true,
      
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/dataList.vue')
  },
  {
    path: '/myBag',
    name: 'myBag',
    component: () => import(/* webpackChunkName: "about" */ '../views/myBag.vue')
  },
  {
    path: '/selDataList',
    name: 'selDataList',
    meta:{
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/selDataList.vue')
  },
  {
    path: '/dataDetail',
    name: 'dataDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/dataDetail.vue')
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/adminLogin.vue')
  },
  {
    path: '/codeScan',
    name: 'codeScan',
    component: () => import(/* webpackChunkName: "about" */ '../views/codeScan.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }


export default router
